<template>
  <v-container>
    <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="alertError!=''">
      {{alertError}}
    </v-alert>
    <v-alert text prominent type="success" icon="mdi-cloud-alert" v-if="alertSuccess!=''">
      {{alertSuccess}}
    </v-alert>
    <v-card color="grey lighten-4" flat tile>
        <v-toolbar dense>
        <v-toolbar-title>Manage Individual Teacher</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="OpenModalNew">
            <v-icon>mdi-plus</v-icon>
            New Individual Teacher
        </v-btn>
        </v-toolbar>
    </v-card>
    <v-card class="my-10">
      <v-row justify="center">
        <v-col cols="3">
          <v-btn
            class="ma-2"
            color="info"
            @click="ByWeb"
          >
            Registered by web
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn
            class="ma-2"
            color="info"
            @click="ByLeeLee"
          >
            Registered by Lee Lee
          </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="5">
          <v-menu v-model="PickerBeginDateModal" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="PickerBeginDate" outlined label="Begin Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="PickerBeginDate" @input="PickerBeginDateModal = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="5">
          <v-menu v-model="PickerEndDateModal" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="PickerEndDate" outlined label="End Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="PickerEndDate" @input="PickerEndDateModal = false" :min="PickerBeginDate"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <v-btn @click="SearchByDates" block class="my-auto">
            <v-icon>mdi-card-search-outline</v-icon>
            Search
          </v-btn>
        </v-col>
      </v-row> -->
    </v-card>
    <v-data-table :headers="tableHeader" :items="teachersFound" sort-by="Name" class="elevation-1">
        <template v-slot:top>
          <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to delete?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteUserConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-icon small class="mr-2" @click="editUser(item)">
            mdi-pencil
          </v-icon> -->
          <v-icon small @click="deleteUser(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>

    <v-dialog v-model="dialogUser" max-width="1000px">
        <v-card>
            <v-form ref="form" v-model="valid" @submit.prevent="save" lazy-validation>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="user.name" outlined :rules="nameRules" label="Full Name" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="user.email" outlined :rules="emailRules"  label="Email"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select v-model="user.country" outlined :items="listCountries" item-text="name" menu-props="auto" label="Select Countries" hide-details return-object single-line></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select v-model="user.language" outlined :items="languages" item-text="name" item-value="id" label="Select Language"></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu v-model="PickerExpiration" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="user.PickerExpiration" outlined label="Date Expiration" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="user.PickerExpiration" @input="PickerExpiration = false" :min="nowDate"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="editedUID == -1">
                        <v-text-field v-model="user.password" outlined :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="[passwordRules.required, passwordRules.min]" :type="showPassword ? 'text' : 'password'" label="Password" counter @click:append="showPassword = !showPassword"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" type="submit" text>
                    Save
                  </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLoad" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Wait please
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { db, functions } from '../../../plugins/firebase'
import firebase from 'firebase'
export default {
  data () {
    return {
      users: [],
      tableHeader: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Download', value: 'downloads' },
        { text: 'Expiration Date', value: 'expiration' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      teachersFound: [],
      user: {
        name: '',
        email: '',
        password: '',
        country: '',
        language: '',
        PickerExpiration: ''
      },
      PickerBeginDateModal: false,
      PickerEndDateModal: false,
      PickerBeginDate: '',
      PickerEndDate: '',
      listCountries: [],
      languages: [],
      nameRules: [
        v => (v && v.length >= 2) || 'Minimum length is 2 characters',
        v => (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 4 || 'Min 4 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      clearUser: {
        name: '',
        email: '',
        password: '',
        country: '',
        language: '',
        PickerExpiration: ''
      },
      editedUID: -1,
      dialogUser: false,
      dialogDelete: false,
      dialogLoad: false,
      valid: true,
      alertError: '',
      alertSuccess: '',
      showPassword: false,
      PickerExpiration: false,
      nowDate: new Date().toISOString().slice(0,10)
    }
  },
  created () {
    //this.Users();
    
  },
  methods: {
    OpenModalNew() {
      this.dialogUser = true;
      if (this.listCountries.length == 0) {
          this.GetCountries();
      }
      if (this.languages.length == 0) {
          this.GetLanguages();
      }
    },
    editUser (data) {
      this.editedUID = data.id;
      this.user = Object.assign({}, data)
      this.dialogUser = true
    },
    deleteUser (data) {
      this.editedUID = data.id;
      this.user = Object.assign({}, data)
      this.dialogDelete = true
    },
    deleteUserConfirm () {
      this.deleteData(this.editedUID).then((answer) => {
        if (answer.result) {
          this.alertSuccess = "Deleted successfully";
          this.SearchByDates()
        } else {
          this.alertError = answer.message;
        }
      });
      this.closeDelete()
    },
    close () {
      this.dialogUser = false
      this.$refs.form.resetValidation()
      this.$nextTick(() => {
        this.user = Object.assign({}, this.clearUser)
        this.editedUID = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.user = Object.assign({}, this.clearUser)
        this.editedUID = -1
      })
    },
    save () {
      
      if (!this.$refs.form.validate()) return;
      if (this.editedUID !== -1) {
        this.dialogLoad = true;
        this.updateData(this.user).then(response => {
          console.log(response);
          if (response.result) {
            this.alertSuccess = "updated successfully";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          } else {
            let principal = this;
            if (Object.prototype.hasOwnProperty.call(response.message, 'code')) {
              switch (response.message.code) {
                case 'not-found':
                  this.alertError = 'not found user';
                  break;
                default:
                  this.alertError = response.message.code;
              }
            } else {
              this.alertError = response.message;
            }
            setTimeout(function () { principal.alertError = '' }, 3000);
          }
          this.dialogLoad = false
        });
      } else {
        this.dialogLoad = true;
        this.createData(this.user).then(response => {
          console.log(response);
          if (!response.result) {
            this.alertError = response.message;
            let principal = this;
            setTimeout(function () { principal.alertError = '' }, 3000);
          } else {
            this.alertSuccess = "created successfully";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          }
          this.dialogLoad = false
        });
      }
      this.close()
      this.$refs.form.resetValidation()
    },
    validate () {
      this.$refs.form.validate()
    },
    // llamadas a firebase
    async createData (user) {
      let answer = { result: '', message: '' }
      try {      
        const setAdmin = functions.httpsCallable('createIndividualTeacher')
        let res=await setAdmin(user)
        let diaAux = new Date(user.PickerExpiration)
        diaAux.setDate(diaAux.getDate() + 1);
        db.collection('IndividualTeacher').doc(res.data.user.uid).update({LicenseExpirationTimestamp: firebase.firestore.Timestamp.fromDate(diaAux).toDate()})
        answer.result = true;
      } catch (error) {
        answer.result = false;
        answer.message = error;        
      }
      return answer;
    },
    async updateData (user) {
      let answer = { result: '', message: '' }
      try {
        user.table = this.table;
        const updatetAdmin = functions.httpsCallable('updateUserPrivate')
        await updatetAdmin(user)
          .then(() => {
            answer.result = true;
          })
      } catch (error) {
        answer.result = false;
        answer.message = error;
        console.log('entro al log general');
      }
      return answer;
    },
    async Users () {
      await db.collection(this.table).onSnapshot((snapshotChange) => {
        this.users = [];
        snapshotChange.forEach((doc) => {
          let oneStudent = doc.data();
          oneStudent.id = doc.id;
          this.users.push(oneStudent);
        });
      }, (error) => {
        console.log(error);
      })
    },
    async GetCountries() {
        let list = await db.collection('SchoolsData').doc('RegionSchoolD').get();
        //console.log(list.data())
        let ids = Object.keys(list.data().Countries);
        let countries = [];
        ids.forEach(element => {
            console.log(element)
            countries.push({ id: element, name: list.data().Countries[element] })
        });
        this.listCountries = countries;
        console.log(countries)
    },
    async GetLanguages() {
        let languages = await db.collection('SchoolsData').doc('Languages').get();
        let list = languages.data().LanguageList;
        list.forEach((element, index) => {
          this.languages.push({ id: index, name: element })
        });
        console.log(this.languages)
        //this.languages = list;
    },
    async deleteData (id) {
      let answer = { resutl: '', message: '' }
      let uidLogin = this.$store.state.user.uid;
      if (uidLogin === id) {
        answer.mensaje = 'this user is actually login';
        answer.resultado = false;
        return answer;
      }
      try {
        const deleteAdmin = functions.httpsCallable('deleteIndividualTeacher')
        await deleteAdmin(id)
        answer.result = true;  
      } catch (error) {
        answer.message = error;
        answer.resutl = false;
      }
      return answer;
    },
    SearchByDates (){
      if (this.PickerBeginDate == '' || this.PickerEndDate == '') {
        this.alertError = 'Necesita seleccionar las fechas.';
        let principal = this;
        setTimeout(function () { principal.alertError = '' }, 3000);
        return;
      }
      let start = new Date(this.PickerBeginDate);
      let end = new Date(this.PickerEndDate);
      console.log(start)      
      let listResult = []
      db.collection('IndividualTeacher')
        .where('LicenseExpirationTimestamp', '>=', start)
        .where('LicenseExpirationTimestamp', '<=', end).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log(doc.data());
            listResult.push({ id: doc.data().UserUID, name: doc.data().Name, email: doc.data().Email, expiration: doc.data().LicenseExpirationDate })
            
          });
          this.teachersFound = listResult;
        });
    },
    ByWeb(){
      this.dialogLoad = true;
      let listResult = []
      db.collection('IndividualTeacher')
        .where('LicenseExpirationDate', '==', "").get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log(doc.data());
            let download = false;
            if (Object.prototype.hasOwnProperty.call(doc.data(), 'Downloads')) {
              if (Object.keys(doc.data().Downloads).length > 0) {
                download = true;
              }
            }
            listResult.push({ id: doc.data().UserUID, name: doc.data().Name, email: doc.data().Email, expiration: doc.data().LicenseExpirationDate, IsFree: doc.data().free, downloads: download })
            
          });
          this.teachersFound = listResult;
          this.dialogLoad = false;
        });
    },
    ByLeeLee(){
      this.dialogLoad = true;
      let listResult = []
      db.collection('IndividualTeacher')
        .where('LicenseExpirationDate', '!=', "").get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log(doc.data());
            let download = false;
            if (Object.prototype.hasOwnProperty.call(doc.data(), 'Downloads')) {
              if (Object.keys(doc.data().Downloads).length > 0) {
                download = true;
              }
            }
            listResult.push({ id: doc.data().UserUID, name: doc.data().Name, email: doc.data().Email, expiration: doc.data().LicenseExpirationDate, IsFree: doc.data().free, downloads: download })
            
          });
          this.teachersFound = listResult;
          this.dialogLoad = false;
        });
    } 
  },
  computed: {
    formTitle () {
      return this.editedUID === -1 ? 'New Individual Teacher' : 'Edit Individual Teacher'
    },
  },
  watch: {
    dialogUser (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogLoad (val) {
      // if (!val) return
      // console.log(this.$nuxt.isOffline);
      // setTimeout(() => (this.dialogLoad = false), 4000)
    },
  }
}
</script>
